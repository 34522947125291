import React, { useEffect, useState } from 'react';
import {Alert, Grid, IconButton, Collapse, AlertTitle, CircularProgress} from "@mui/material";
import MarketingCampaign from 'app/shared/widgets/MarketingCampaign/MarketingCampaign';
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import RedeemIcon from '@mui/icons-material/Redeem';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Referralusers from 'app/shared/widgets/ReferralUsers/ReferralUsers';
import { roidata, referralusers, lasttransactions } from 'backendServices/ApiCalls';
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import CloseIcon from "@mui/icons-material/Close";
import { Link } from 'react-router-dom';
import Div from '@jumbo/shared/Div/Div';
import { StackedLineChart } from '@mui/icons-material';

const Crypto = () => {
    const [userroidata,setUserRoiData]=useState([])
    const [lasttransactionsdata,setLastTransactionsData]=useState([])
    const [picturelink,setPictureLink]=useState([])
    const [referralusersdata,setReferralUsersData]=useState([])
    const [open, setOpen] = React.useState(true);
    const {loginUserData, loading, handleOpen} = useContext(CustomProvider);
    let userData = loginUserData


    const RoiData =()=>{
        roidata((response) => {
            setUserRoiData(response?.data?.data)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
    const TransactionData =()=>{
        lasttransactions((response) => {
            setLastTransactionsData(response?.data?.data?.enteries)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    const ReferralUsers =()=>{
        referralusers((response) => {
            setReferralUsersData(response?.data?.data?.enteries)
            setPictureLink(response?.data?.data?.picturelink)
        }, (error) => {
            console.log(error?.response?.data);
        })
    } 

    useEffect(()=>{
        RoiData();
        ReferralUsers();
        TransactionData();
    },[])




    if(loading){
        return  <Div
        sx={{
            display: 'flex',
            minWidth: 0,
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
        }}
      >
        <CircularProgress sx={{m: '-40px auto 0'}}/>
      </Div>
      }

    return (
        <Grid container spacing={2}>
            <Grid container sx={{ alignItems: 'center',
                                justifyContent: 'center',
                                mt:1}} >
            <Grid item xs={12} sm={12} lg={6}>
                           <Collapse in={open} xs={12} sm={12} lg={12} >
                           {
   userData.status === 'downgraded' ? (
       
    <Alert severity="error" sx={{marginBottom:'20px'}}>
        <AlertTitle><strong>Alert!</strong></AlertTitle>
        As per our program requirements, users are required to invite two active members within 72 hours of joining. This allows our process to continue smoothly and provide benefits to our members. <br/>
Unfortunately, we have noticed that you did not refer two active members, and as a result, your account has been downgraded.<br/>
To continue participating in the program, we welcome you to create a new account.<br/>
If you have any questions or need further assistance, please don't hesitate to reach out to our support team.<br/>
Thank you for your understanding.
    </Alert>

   ):null 
}

{
   userData.show_notification !== null && userData.show_notification !== ""  ? (
       
    <Alert severity="warning" sx={{marginBottom:'20px'}}>
        <AlertTitle><strong>Alert!</strong></AlertTitle>
        Your referral <b>{userData.show_notification}</b> has been removed from your tree due to non-compliance with our program. We kindly request you to invite a new user within 72 hours to maintain your membership.
    </Alert>

   ):null 
}
                    <Alert 
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                        sx={{mb: 2}}
                    >
                        You will get your payment when you complete your 15 levels
                    </Alert>
                    
                    {
                    userData.activereferrals === '0' && userData.status === 'pending' ? (
                    <>
                    {
                        userData.nda_signed === '0' ? (<Alert severity="warning" sx={{mb: 2}}><AlertTitle><strong>Step 1</strong></AlertTitle>Please sign this non-disclosure agreement <strong><Link to='/non-disclosure'>Click Here</Link></strong></Alert>) : null
                    }
                    

                    <Alert severity="warning"><AlertTitle><strong>Step 2</strong></AlertTitle>Your membership status is Pending <strong><Link to='/donation'>Donate Now</Link></strong></Alert>

                    <Alert severity="warning" style={{marginTop:'20px'}}><AlertTitle><strong>Step 3</strong></AlertTitle>Please invite your two referrals <a href='javasccript:void(0)'  onClick={handleOpen}>Send referral invitation</a></Alert>

                    </>) 
                    : 
                    userData.activereferrals === '0' && userData.status === 'approved' ? (<>                    <Alert severity="warning" style={{marginTop:'20px'}}><AlertTitle><strong>Step 2</strong></AlertTitle>Please invite your two referrals <a href='javasccript:void(0)'  onClick={handleOpen}>Send referral invitation</a></Alert></>) 
                    : 
                    userData.activereferrals === '1' ? (<><Alert severity="info"><AlertTitle>Info</AlertTitle>Your membership status is Pending. You have to invite one more active member for upgrade your membership to <strong>Standard</strong> <a href='javasccript:void(0)'  onClick={handleOpen}>Send referral invitation</a></Alert></>)
                    :
                    userData.activereferrals === '2' ? (<Alert severity="success"><AlertTitle>Success</AlertTitle><strong>You are upgraded to standard membership</strong></Alert>)
                    :
                    null
                    } 
                
                </Collapse>
                </Grid>
                </Grid>
             <Grid item xs={12} sm={6} lg={3}>
                <ObjectCountRevenue value={userData?.completedlevelusers*5} title='walletbalance' color="primary.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large'/>} vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ObjectCountRevenue value={userData?.levelcompleted} title='completedlevels' color="info.main"  icon={<StackedLineChart fontSize='large'/>}  vertical={true}/>
            </Grid> 
            <Grid item xs={12} sm={6} lg={3}>
                <ObjectCountRevenue value={userData.membership} title='membership' color="secondary.main"  icon={<AccountBalanceWalletRoundedIcon fontSize='large'/>}  vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ObjectCountRevenue value={userData?.totalpayout} title='tpayout' color="success.main"  icon={<RedeemIcon fontSize='large'/>}  vertical={true}/>
            </Grid>
           
            {/* <Grid item xs={12} lg={6}>
                <PortfolioBalance totalroi={userroidata?.roiGroupData?.totalroi} weeklyroi={userroidata?.roiGroupData?.weeklyroi} monthlyroi={userroidata?.roiGroupData?.monthlyroi} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <EarningExpenses earning={userData?.totalearning} investment={userData?.investment}/>
            </Grid> */}
            <Grid item xs={12} lg={6}>
                <Referralusers picturelink={picturelink} referralusersdata={referralusersdata}/>
            </Grid>
            <Grid item xs={12} lg={6}>
                <MarketingCampaign lasttransactionsdata={lasttransactionsdata}/>
            </Grid>
        </Grid>
    );
};

export default Crypto;
